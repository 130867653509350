import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const PaymentsSection = () => {
  const data = useStaticQuery(graphql`
    query {
      paymentApplepay: file(
        relativePath: { eq: "products/payment-applepay.svg" }
      ) {
        publicURL
      }
      paymentGooglepay: file(
        relativePath: { eq: "products/payment-googlepay.svg" }
      ) {
        publicURL
      }
      paymentMasterpass: file(
        relativePath: { eq: "products/payment-masterpass.svg" }
      ) {
        publicURL
      }
      paymentPix: file(relativePath: { eq: "products/payment-pix.svg" }) {
        publicURL
      }
      paymentCard: file(relativePath: { eq: "products/payment-card.svg" }) {
        publicURL
      }
      appStoreIcon: file(relativePath: { eq: "products/app-store.svg" }) {
        publicURL
      }
      googlePlayIcon: file(relativePath: { eq: "products/google-play.svg" }) {
        publicURL
      }
      appStoreDesktop: file(
        relativePath: { eq: "products/app-store-desktop.svg" }
      ) {
        publicURL
      }
      googlePlayDesktop: file(
        relativePath: { eq: "products/google-play-desktop.svg" }
      ) {
        publicURL
      }
      mockup: file(
        relativePath: { eq: "products/payments-mockup.png" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-payments">
      <div className="mockup"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-3 is-hidden-mobile">
            <img src={data.mockup.publicURL} alt=""/>
          </div>
          <div className="column is-offset-1">
            <h2 className="title has-text-white">
              Pague como <br />
              quiser
            </h2>
            <p className="subtitle">
              Maior diversidade de meios de <br />
              pagamento do mercado.
            </p>

            <div className="payment-methods">
              <ul className="is-clearfix">
                <li className="is-pulled-left payment-method">
                  <img src={data.paymentApplepay.publicURL} alt="Apple Pay" />
                </li>
                <li className="is-pulled-left payment-method">
                  <img src={data.paymentGooglepay.publicURL} alt="Google Pay" />
                </li>
                <li className="is-pulled-left payment-method">
                  <img src={data.paymentPix.publicURL} alt="Pix" />
                </li>
                <li className="is-pulled-left payment-method">
                  <img
                    src={data.paymentMasterpass.publicURL}
                    alt="Marterpass"
                  />
                </li>
                <li className="is-pulled-left payment-method">
                  <img
                    src={data.paymentCard.publicURL}
                    alt="Cartão de crédito e débito"
                  />
                </li>
              </ul>
              <p className="methods-text">
                Apple Pay, Google Pay, Masterpass, cartão de crédito, débito, transferência e boleto.
              </p>
            </div>

            <div className="download-links is-clearfix">
              <p className="title has-text-white">Disponível nas lojas:</p>

              <p className="has-text-centered">
                <a
                  href="https://apps.apple.com/br/app/zul-zona-azul-digital-cet-sp/id1188772766"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="is-hidden-desktop"
                    src={data.appStoreIcon.publicURL}
                    width="149"
                    height="41"
                    alt="Apple App Store"
                  />

                  <img
                    className="is-hidden-mobile"
                    src={data.appStoreDesktop.publicURL}
                    width="160"
                    height="48"
                    alt="Apple App Store"
                  />
                </a>
              </p>
              <p className="has-text-centered">
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.zuldigital"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="is-hidden-desktop"
                    src={data.googlePlayIcon.publicURL}
                    width="149"
                    height="41"
                    alt="Google Play"
                  />

                  <img
                    className="is-hidden-mobile"
                    src={data.googlePlayDesktop.publicURL}
                    width="160"
                    height="48"
                    alt="Apple App Store"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PaymentsSection
